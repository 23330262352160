body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Custom styles for Events component */
.card-img-custom {
  height: 200px;
  object-fit: cover;
}

.modal-img-custom {
  max-height: 300px;
  object-fit: cover;
}

.button-custom-primary {
  background-color: #042556;
  color: #fff;
  border: none;
}

.button-custom-primary:hover {
  background-color: #063a6a;
  color: #fff;
}

.custom-modal .modal-content {
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

button {
  border-radius: 10px !important;
  transition: all 0.3s ease;
}

button:hover {
  transform: translateY(-2px);
}

form .form-control {
  border-radius: 10px !important;
  border: 1px solid #ced4da;
  padding: 12px;
}

form .form-control:focus {
  box-shadow: 0 0 10px rgba(0, 123, 255, 0.3);
  border-color: #007bff;
}
