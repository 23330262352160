.hero-section {
  position: relative;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.hero-video,
.hero-carousel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.hero-carousel-image {
  height: 100vh;
  object-fit: cover;
}

.hero-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.6); /* Black background with opacity */
  color: white;
  z-index: 1;
}

.navbar-brand,
.nav-link {
  color: white;
}

/* Define the font globally or in your specific component CSS file */

body,
.navbar-brand,
.hero-content h1,
.card-title {
  font-family: "MyCustomFont", sans-serif;
}

.hero-content p,
.card-text {
  font-family: "MyCustomFont", sans-serif;
  font-style: normal;
}

.hero-content p.italic,
.text-muted {
  font-family: "MyCustomFont", sans-serif;
  font-style: italic; /* Use the italic font style */
}
