.hero-section {
  position: relative;
  height: 100vh;
  overflow: hidden;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.hero-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.hero-overlay {
  position: relative;
  z-index: 1;
  max-width: 800px;
  padding: 20px;
  background: rgba(0, 0, 0, 0.7); /* Optional: for improved text readability */
  border-radius: 8px;
}

.navbar-brand,
.nav-link {
  color: white;
}

.hero-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4); /* Adds a dark overlay */
  z-index: 0;
}

.hero-overlay h1 {
  font-size: 3.5rem; /* Increase the header size */
  font-weight: 300; /* Make the font thinner */
  line-height: 1.2; /* Adjust line height for better readability */
}

.hero-overlay p {
  font-size: 1.25rem; /* Optional: slightly increase paragraph text size */
  font-weight: 400; /* Keep paragraph text weight normal */
}

@font-face {
  font-family: "MyCustomFont";
  src: url("./fonts/Rubik-VariableFont_wght.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "MyCustomFont";
  src: url("./fonts/Rubik-Italic-VariableFont_wght.ttf") format("truetype");
  font-weight: normal;
  font-style: italic; /* Specify this as italic */
}
